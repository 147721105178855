<template>
  <iframe
    :src="url"
    frameborder="0"
    width="100%"
    height="100%"
  ></iframe>
</template>

<script>
export default {
  data() {
    return {
      url: ""
    };
  },
  async mounted() {
    var res = await this.$axios.get(
      this.$config.service +
        "/api/public/planet/screening?modelId=" + this.$route.query.modelId
    );

    res = res.data;
    if(!res.success) return;

    this.url = `${this.$config.planetUrl}?sn=${res.data.sn}&framemessage=1&user=${res.data.user}`

    window.addEventListener("message", ({data}) => {

      document.body.innerHTML = "";

      data = JSON.parse(data);
      if(data.message != "finished") return;

      var target = `${this.$config.vrUrl}?m=${res.data.modelId}&refresh=1&hideToolbar=1&user=${res.data.user}`;

      target += "&tagImage=" + res.data.sweepsGroup.map(group => group.tagId).filter(x => x).join(",")

      location.href = target;

    });
  },
};
</script>

<style lang="less" scoped>
</style>