<template>
  <div>
    <a-layout id="components-layout-demo-fixed">
      <a-layout-header theme="light">
        <img src="@/assets/images/logo.png" alt="" style="height: 50px" />
        <span class="menu">VR场景调研配置列表</span>
        <img
          src="@/assets/images/avatar.png"
          alt=""
          style="height: 60px; float: right"
        />
      </a-layout-header>
      <a-layout-content style="padding: 50px; padding-top: 30px; padding-bottom: 0px">
        <slot />
      </a-layout-content>
      <a-layout-footer :style="{ textAlign: 'center' }">
        PlanetG ©2022 Created by Wemark
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
export default {
  beforeCreate(){
    if(!this.$utils.getToken()) this.$router.replace("/login")
  }
};
</script>

<style scoped lang="less">
.menu {
  display: inline-block;
  line-height: 64px;
  margin-left: 107px;
  font-size: 16px;
  color: #1c6efc;
  border-bottom: solid 2px #1c6efc;
}
</style>