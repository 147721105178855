<template>
  <AuthFrame>
    <div style="background-color: #fff; padding: 20px">
      <div>
        <h3 style="display: inline-block">配置列表</h3>
        <a-button type="primary" @click="createConfig()" style="float: right">
          创建VR调研
        </a-button>
      </div>
      <a-table :columns="columns" :data-source="data" style="margin-top: 20px">
        <span slot="action" slot-scope="text, record">
          <a @click="edit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="delConfirm(record)">删除</a>
        </span>
      </a-table>
      <a-modal
        title="配置"
        width="800px"
        :visible="createDialog.visible"
        okText="确认"
        cancelText="取消"
        @cancel="createDialog.visible = false"
        @ok="submit"
      >
        <a-form-model
          v-if="createDialog.visible"
          :labelCol="{ span: 6 }"
          :wrapperCol="{ span: 14 }"
          :model="createDialog.config"
          :rules="createDialog.rules"
        >
          <a-form-model-item label="模型ID" prop="modelId">
            <a-input
              v-model="createDialog.config.modelId"
              placeholder="模型ID"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="甄别问卷ID">
            <a-input v-model="createDialog.config.sn" placeholder="甄别问卷ID"></a-input>
          </a-form-model-item>
          <a-form-model-item
            :label="'分组' + (index + 1)"
            v-for="(group, index) in createDialog.config.sweepsGroup"
            :key="index"
          >
            <a-row style="text-align: center">
              <a-col :span="14">
                <a-input v-model="group.tagId" placeholder="结束标志点位ID"></a-input>
              </a-col>
              <a-col :span="7">
                <a-input v-model="group.dalay" placeholder="分组延迟"></a-input>
              </a-col>
            </a-row>
            <a-row
              v-for="(sweep, index) in group.sweeps"
              :key="index"
              style="text-align: center"
            >
              <a-col :span="7">
                <a-input v-model="sweep.id" placeholder="站位点ID"></a-input>
              </a-col>
              <a-col :span="7">
                <a-input v-model="sweep.dalay" placeholder="延时(s)"></a-input>
              </a-col>
              <a-col :span="7">
                <a-input v-model="sweep.sn" placeholder="问卷ID"></a-input>
              </a-col>
              <a-col :span="3">
                <a
                  @click="group.sweeps = group.sweeps.filter((s) => s != sweep)"
                  >删除</a
                >
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="14">
                <a @click="group.sweeps.push({ id: '', dalay: '', sn: '' })"
                  >添加点位</a
                >
                <a-divider type="vertical" />
                <a
                  @click="
                    createDialog.config.sweepsGroup =
                      createDialog.config.sweepsGroup.filter((g) => g != group)
                  "
                  >删除分组</a
                >
                <template
                  v-if="createDialog.config.sweepsGroup.length <= index + 1"
                >
                  <a-divider type="vertical" />
                  <a
                    @click="
                      createDialog.config.sweepsGroup.push({
                        tagId: '',
                        dalay: '',
                        sweeps: [],
                      })
                    "
                    >添加分组</a
                  >
                </template>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item label="开始提示语">
            <a-textarea :rows="4"
              v-model="createDialog.config.introduce"
              placeholder="开始提示语"
            ></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="结束跳转链接">
            <a-input
              v-model="createDialog.config.finishedUrl"
              placeholder="结束跳转链接"
            ></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </AuthFrame>
</template>

<script>
import AuthFrame from "@/componenets/AuthFrame.vue";
export default {
  components: {
    AuthFrame,
  },
  data() {
    const columns = [
      {
        title: "模型",
        dataIndex: "modelId",
        key: "modelId",
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        key: "createTime",
      },
      {
        title: "操作",
        width: 150,
        key: "action",
        scopedSlots: { customRender: "action" },
      },
    ];
    const data = [];

    return {
      createDialog: {
        visible: false,
        config: null,
        rules: {
          modelId: [
            {
              required: true,
              message: "请输入",
              trigger: "blur",
            },
          ],
        },
      },
      data,
      columns,
    };
  },

  mounted() {
    this.refresh();
  },

  methods: {
    async refresh(){
      var res = await this.$axios.get(
        this.$config.service + "/api/public/planet/all"
      );

      res.data.data.forEach(row => {
        row.createTime = row.createTime ? this.$utils.dateFormat(new Date(row.createTime)) : row.createTime
      })
      this.data = res.data.data;
    },
    async createConfig() {
      this.createDialog.config = {
        sn: "",
        modelId: "",
        sweepsGroup: [
          {
            tagId: "",
            dalay: 0,
            sweeps: [],
          },
        ],
      };
      this.createDialog.visible = true;
    },
    edit(record) {
      this.createDialog.config = JSON.parse(JSON.stringify(record));
      this.createDialog.visible = true;
    },
    async submit() {
      await this.$axios.post(
        this.$config.service + "/api/public/planet/add",
        this.createDialog.config
      );
      this.createDialog.visible = false;
      this.refresh();
    },
    async delConfirm(record) {
      await this.$confirm({
        title: "删除",
        content: "确认删除?",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.del(record);
        },
      });
    },
    async del(record) {
      await this.$axios.post(
        this.$config.service + "/api/public/planet/delete",
        {
          _id: record._id,
        }
      );
      this.refresh();
    },
  },
};
</script>

<style scoped lang="less">
</style>