<template>
  <iframe src="https://www.automark.cc/show?m=v32sJpyAn2J&refresh=1&refresh_html=1" frameborder="0" width="100%" height="100%"></iframe>
</template>

<script>
export default {
  data() {
    return {
      url: "",
    };
  },
  async mounted() {
  },
};
</script>

<style lang="less" scoped>
</style>