import Vue from "vue";
import Router from "vue-router";

import Login from "@/views/public/Login.vue";
import Entrance from "@/views/public/Entrance.vue";

import PlanetList from "@/views/auth/PlanetList.vue";


import Entrance2 from "@/views/public/Entrance2.vue";

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/planetlist"
    },
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/entrance",
      component: Entrance,
    },
    {
      path: "/planetlist",
      component: PlanetList,
    },
    {
      path: "/entrance2",
      component: Entrance2,
    },
  ]
})